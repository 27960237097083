import {createEntitySlice, serializeAxiosError} from "../reducers/reducer.utils";
import {EntityState} from "../../../components/shared/interface/interfaces";
import {defaultValue, IAccount} from "../model/account.model";
import {createAsyncThunk, isFulfilled, isPending} from "@reduxjs/toolkit";
import axios from "axios";
import {cleanEntity} from "../reducers/entity.utils";
import {IInviteeSubscription} from "../model/inviteeSubscription.model";
import {IOrganization} from "../model/organization.model";

const initialState: EntityState<IAccount> | any = {
    entities: [],
    entity: defaultValue,
    errorMessage: null,
    loading: false,
    updateSuccess: false,
    updating: false,
};

const apiUrl = 'api/organization';

// ------------------------ new api -----------------------
export const getListOfCurrentUserOrganizationAPI = createAsyncThunk(
    'organization/fetch_getListOfCurrentUserOrganizationAPI',
    async () => {
        const requestUrl = `${apiUrl}/getListOfCurrentUserOrganization`;
        return axios.get<any>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)

export const getOrganizationByIdAPI = createAsyncThunk(
    'organization/fetch_getOrganizationByIdAPI',
    async (entity: { id: number }) => {
        const requestUrl = `${apiUrl}/getOrganizationById`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const createOrganizationAPI = createAsyncThunk(
    'organization/fetch_createOrganizationAPI',
    async (entity: IOrganization) => {
        const requestUrl = `${apiUrl}/createOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const updateOrganizationAPI = createAsyncThunk(
    'organization/fetch_updateOrganizationAPI',
    async (entity: IOrganization) => {
        const requestUrl = `${apiUrl}/updateOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const deleteOrganizationAPI = createAsyncThunk(
    'organization/fetch_deleteOrganizationAPI',
    async (entity: { id: number }) => {
        const requestUrl = `${apiUrl}/deleteOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
// ------------------------ new api -----------------------

export const getAllInvitationListAPI = createAsyncThunk(
    'organization/fetch_AllInvitationList',
    async () => {
        const requestUrl = `${apiUrl}/getAllInvitationList`;
        return axios.get<any>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const getUnAssignedUserSubscriptionByOrganizationIdAPI = createAsyncThunk(
    'organization/fetch_getUnAssignedUserSubscriptionByOrganizationIdAPI',
    async () => {
        const requestUrl = `${apiUrl}/getUnAssignedUserSubscriptionByOrganizationId`;
        return axios.get<any>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const inviteToOrganizationAPI = createAsyncThunk(
    'organization/fetch_inviteToOrganizationAPI',
    async (entity: { invitee_email: string }) => {
        const requestUrl = `${apiUrl}/inviteToOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const bulkInviteToOrganizationAPI = createAsyncThunk(
    'organization/fetch_bulkInviteToOrganizationAPI',
    async (entity: []) => {
        const requestUrl = `${apiUrl}/bulkInviteToOrganization`;
        return axios.post(requestUrl, cleanEntity(entity)); // cleanEntity to sanitize the data if needed
    },
    {serializeError: serializeAxiosError}
);
export const buyBulkSubscriptionAPI = createAsyncThunk(
    'organization/fetch_buyBulkSubscriptionAPI',
    async (entity: { basic: number, premium: number }) => {
        const requestUrl = `${apiUrl}/buyBulkSubscription`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const buyBulkSubscriptionWithDiscountAPI = createAsyncThunk(
    'organization/fetch_buyBulkSubscriptionWithDiscountAPI',
    async (entity: { basic: number, premium: number }) => {
        const requestUrl = `${apiUrl}/buyBulkSubscriptionWithDiscount`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const getListOfCurrentUserJoinedOrganizationAPI = createAsyncThunk(
    'organization/fetch_getListOfCurrentUserJoinedOrganizationAPI',
    async () => {
        const requestUrl = `${apiUrl}/getListOfCurrentUserJoinedOrganization`;
        return axios.get<any>(requestUrl);
    },
    {serializeError: serializeAxiosError}
)
export const setCurrentUserSelectedOrganizationIdAPI = createAsyncThunk(
    'organization/fetch_setCurrentUserSelectedOrganizationIdAPI',
    async (entity: { id: number | null }) => {
        const requestUrl = `${apiUrl}/setCurrentUserSelectedOrganizationId`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const subscribeToPlanBulkForOrgAPI = createAsyncThunk(
    'organization/fetch_subscribeToPlanBulkForOrgAPI',
    async (entity: IInviteeSubscription[]) => {
        const requestUrl = `${apiUrl}/subscribeToPlanBulkForOrg`;
        return axios.post<any>(requestUrl, entity);
    },
    {serializeError: serializeAxiosError}
)
export const getOrganizationByEmailTokenAPI = createAsyncThunk(
    'organization/fetch_getOrganizationByEmailTokenAPI',
    async (entity: { email_token: string }) => {
        const requestUrl = `${apiUrl}/getOrganizationByEmailToken`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const acceptInviteToOrganizationAPI = createAsyncThunk(
    'organization/fetch_acceptInviteToOrganizationAPI',
    async (entity: { email_token: string, password: string }) => {
        const requestUrl = `${apiUrl}/acceptInviteToOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const acceptInviteToOrganizationExistUserAPI = createAsyncThunk(
    'organization/fetch_acceptInviteToOrganizationExistUserAPI',
    async (entity: { email_token: string }) => {
        const requestUrl = `${apiUrl}/acceptInviteToOrganizationExistUser`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const rejectInviteToOrganizationAPI = createAsyncThunk(
    'organization/fetch_rejectInviteToOrganizationAPI',
    async (entity: { email_token: string }) => {
        const requestUrl = `${apiUrl}/rejectInviteToOrganization`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const deleteInviteeAPI = createAsyncThunk(
    'organization/fetch_deleteInviteeAPI',
    async (entity: { invitee_id: number }) => {
        const requestUrl = `${apiUrl}/deleteInvitee`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const assignAdminRoleToInviteeAPI = createAsyncThunk(
    'organization/fetch_assignAdminRoleToInviteeAPI',
    async (entity: { invitee_id: number }) => {
        const requestUrl = `${apiUrl}/assignAdminRoleToInvitee`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const revokeInviteeSubscriptionAPI = createAsyncThunk(
    'organization/fetch_revokeInviteeSubscriptionAPI',
    async (entity: { invitee_id: number }) => {
        const requestUrl = `${apiUrl}/revokeInviteeSubscription`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const editInviteeEmailAPI = createAsyncThunk(
    'organization/fetch_editInviteeEmailAPI',
    async (entity: { invitee_id: number, email: string }) => {
        const requestUrl = `${apiUrl}/editInvite`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)
export const ReAssignUserSubscriptionToInviteeIdAPI = createAsyncThunk(
    'organization/fetch_ReAssignUserSubscriptionToInviteeIdAPI',
    async (entity: { inviteeId: number, userSubscriptionId: string }) => {
        const requestUrl = `${apiUrl}/ReAssignUserSubscriptionToInviteeId`;
        return axios.post<any>(requestUrl, cleanEntity(entity));
    },
    {serializeError: serializeAxiosError}
)


export const organizationSlice = createEntitySlice({
    name: 'organization',
    initialState,

    extraReducers: (builder) => {
        builder
            // .addCase(getMostToolsUsed.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(getMostToolsUsed.fulfilled, (state, action) => {
            //     state.entity = action.payload.data;
            //     state.loading = false;
            // })
            // .addCase(getMostToolsUsed.rejected, (state) => {
            //     (state as any).isAuthenticated = false;
            //     state.loading = false;
            // })
            .addCase(getListOfCurrentUserJoinedOrganizationAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getListOfCurrentUserJoinedOrganizationAPI.fulfilled, (state, action) => {
                (state as any).currentUserJoinedOrganization = action.payload.data;
                state.loading = false;
            })
            .addCase(getListOfCurrentUserJoinedOrganizationAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getOrganizationByIdAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrganizationByIdAPI.fulfilled, (state, action) => {
                state.entity = action.payload.data;
                state.loading = false;
            })
            .addCase(getOrganizationByIdAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getUnAssignedUserSubscriptionByOrganizationIdAPI.pending, (state) => {
                (state as any).loadingUnAssignedUserSubscription = true;
            })
            .addCase(getUnAssignedUserSubscriptionByOrganizationIdAPI.fulfilled, (state, action) => {
                (state as any).entityUnAssignedUserSubscription = action.payload.data;
                (state as any).loadingUnAssignedUserSubscription = false;
            })
            .addCase(getUnAssignedUserSubscriptionByOrganizationIdAPI.rejected, (state) => {
                (state as any).loadingUnAssignedUserSubscription = false;
            })
            .addCase(subscribeToPlanBulkForOrgAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(subscribeToPlanBulkForOrgAPI.fulfilled, (state, action) => {
                (state as any).paymentUrl = action.payload.data;
                state.loading = false;
            })
            .addCase(subscribeToPlanBulkForOrgAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(buyBulkSubscriptionAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(buyBulkSubscriptionAPI.fulfilled, (state, action) => {
                (state as any).paymentUrl = action.payload.data;
                state.loading = false;
            })
            .addCase(buyBulkSubscriptionAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(buyBulkSubscriptionWithDiscountAPI.rejected, (state) => {
                state.loading = false;
            })
            .addCase(buyBulkSubscriptionWithDiscountAPI.fulfilled, (state, action) => {
                (state as any).paymentUrl = action.payload.data;
                state.loading = false;
            })
            .addCase(buyBulkSubscriptionWithDiscountAPI.pending, (state) => {
                state.loading = true;
            })
            .addMatcher(isFulfilled(getOrganizationByEmailTokenAPI), (state: any, action: any) => {
                state.loading = false;
                (state as any).orgFromEmailToken = action.payload.data;
                return state
            })
            .addMatcher(isPending(getOrganizationByEmailTokenAPI), (state: any, action: any) => {
                state.loading = true;
                return state
            })
            .addMatcher(isFulfilled(getListOfCurrentUserOrganizationAPI, getAllInvitationListAPI, inviteToOrganizationAPI, acceptInviteToOrganizationExistUserAPI, acceptInviteToOrganizationAPI, rejectInviteToOrganizationAPI), (state: any, action: any) => {
                const {data} = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isPending(getListOfCurrentUserOrganizationAPI, getAllInvitationListAPI, inviteToOrganizationAPI, acceptInviteToOrganizationExistUserAPI, acceptInviteToOrganizationAPI, rejectInviteToOrganizationAPI), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
        ;
    },
});

export const {reset, logoutSession, authError, clearAuth} = organizationSlice.actions;

export default organizationSlice.reducer;
