import React from 'react';
import {Card, Typography} from "@mui/material";
import {IPaymentMethod} from "../../utils/redux/model/paymentMethod.model";

const PaymentMethodCard = ({paymentMethod, selectedPaymentMethod, setSelectedPaymentMethod}: {
    paymentMethod: IPaymentMethod;
    selectedPaymentMethod: number;
    setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<number>>;
}) => {
    return (
        <div className="PaymentMethodWidth">
            <Card key={paymentMethod.id}
                  className={selectedPaymentMethod === paymentMethod.id ? "col-12 border border-3 bg-light p-0" : "col-12 border p-0"}
                  variant="elevation"
                  onClick={() => setSelectedPaymentMethod(paymentMethod.id!)}
            >
                <div className="col-12 text-center p-0 m-x">
                    <img className="col-12 p-0 m-0" src={paymentMethod.icon!} alt={paymentMethod.name!}/>
                </div>
                <Typography className="col-12 px-0 text-center text-grey fs-8">{paymentMethod.name}</Typography>
            </Card>
        </div>
    );
};

export default PaymentMethodCard;
